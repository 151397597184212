<template>
  <div>
    <div class="flex-between" style="margin-bottom: 30px">
      <h1>行业资讯</h1>
      <span class="more" @click="handMore()">更多</span>
    </div>
    <div class="nav-list">
      <div
        @click="handNavItem(item, index)"
        class="nav-item"
        :class="navActive == index && 'nav-active'"
        v-for="(item, index) in newsTitleList"
        :key="index"
      >
        {{item.industryInformationClassName}}
      </div>
    </div>
    <div class="new-line"></div>
    <div class="info-left_list">
      <vue-seamless-scroll
        :data="newsList"
        :class-option="optionHover"
        class="seamless-warp"
      >
        <div
          class="info-list-item"
          v-for="(item, index) in newsList"
          :key="index"
        >
        <div class="flex-row">
          <div class="list-icon"></div>
          <a @click="toNewDetail(item)" class="list-item_text">
            {{ item.informationTitle }}
          </a>
        </div>
      
          <div class="text-end">{{ item.dateText || "" }}</div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import { getIndustryInformationPage ,industryInformationCalssList} from "@/api/engineering";
export default {
  data() {
    return {
      newsList: [],
      navActive: 0,
      newsTitleList:[],
      industryInformationClassId:-1,
    };
  },
  computed: {
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  mounted() {
    this.getIndustryInformation();
    this.industryInformationCalssList()
  },
  methods: {
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handMore(){
      this.$router.push('/subwebsite/news')
    },
    async industryInformationCalssList() {
      try {
        const { data } = await industryInformationCalssList({
          current: 1,
          size: 20,
        });
        let records = data.industryInformationCalssList || [];
        this.newsTitleList = records;
        console.log("dasdadxxxxasd", this.newsTitleList);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          current: 1,
          size: 20,
          industryInformationClassId:this.industryInformationClassId
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    handNavItem(item, index) {
      this.industryInformationClassId = item.industryInformationClassId
      this.navActive = index;
      this.getIndustryInformation()
    },
  },
};
</script>
<style>
.menu-item .el-tabs__item:hover {
  color: #ffffff !important;
  background-color: rgba(112, 154, 233, 0.3) !important;
}
.el-carousel__button {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}
.info-left_video .el-carousel__arrow {
  font-size: 30px !important;
  background-color: rgba(31, 45, 61, 0.1);
  color: white;
}
</style>
<style scoped lang="less">
/* 头部背景图 */
.main-header {
  // background-image: url(~@/assets/images/bjtsy/hb1.png);
  background-image: url("https://oss.yanxin7.cn/2023-07-14/a07d8d06364411dc4c8cfd6f86b34680");
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}
.search-for {
  width: 100%;
  height: 330px !important;
  color: white;
  text-align: center;
}

.nav-width {
  height: 40px;
  width: 100%;
  margin: 0 auto;
  background-color: #013c8233;
}

.nav-list {
  display: flex;
  width: 979px;
  font-size: 20px;
  box-sizing: border-box;
  color: #212121;
  overflow-x: hidden;
}
.nav-item {
  min-width: 100px;
  height: 35px;
  cursor: pointer;
  width: 53px;
  margin-right: 30px;
  white-space: nowrap;
  position: relative;
}
::-webkit-scrollbar {
  border-radius: 20px;
  height: 8px;
  background-color: #dfe8f3;
}
.new-line {
  margin-top: -0px;
  width: 979px;
  border-bottom: 1px solid rgb(212, 209, 209);
}
.nav-active {
  position: relative;
  border-bottom: 2px solid #2288fc;
  padding-bottom: 10px;
}


.flex-between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-left_list{
  overflow: hidden;
  height: 260px;
}
.info-list-item:first-child{
  padding-top: 30px;
}
.info-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  cursor: pointer;
}
.info-list-item:hover {
  color: #2288fc !important;
}
.info-list-item a:hover {
  color: #2288fc !important;
}
.list-icon {
  width: 7px;
  height: 7px;
  background: #2288fc;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-right: 24px;
}
.list-item_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 416px;
  text-align: start;
}
.flex-row{
  display: flex;
  align-items: center;
}
.text-end{
  text-align: end;
}
.more{
  cursor: pointer;
}
</style>
