<template>
  <div>
    <subMenu :activeTab="activeTab"></subMenu>
    <div class="flex-between main-box">
      <videoProduct></videoProduct>
      <newProduct></newProduct>
    </div>
    <ecosystemList></ecosystemList>
  </div>
</template>

<script>
import subMenu from "@/components/sub-menu/sub-menu.vue";
import videoProduct from "./components/video/video.vue";
import newProduct from "./components/news/news.vue";
import ecosystemList from "./components/ecosystemList/ecosystemList.vue";
import partner from "./components/partner/partner.vue";
import { mapState } from "vuex";
import {
  urlCommonColumnList,
  urlSystemList,
  getCatory,
  getIndustryInformationPage,
  urlBrowseUserStatTheme1,
} from "@/api/engineering";
export default {
  data() {
    return {
      showLoginForm: false,
      companyList: [],
      videoUrlList: [],
      newsList: [],
      navActive: 0,
      menuList: [
        { label: "首页", name: "home" },
        { label: "资质查询", name: "quation" },
        { label: "金融服务", name: "record" },
        { label: "招标投标", name: "personnel" },
        { label: "机械管理", name: "project" },
        { label: "造价咨询", name: "certificate" },
        { label: "智慧工地", name: "creditscore" },
      ],
      activeTab: "home",
      serviceList: [],
      currentIndex: 0,
      intervalId: null,
    };
  },
  created() { },
  computed: {
    ...mapState("account", ["token", "userInfos"]),
    isLoggedIn() {
      return this.token && this.userInfos;
    },
    optionHover() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    routeRedirect() {
      return this.$route.query.redirect;
    },
  },
  components: {
    subMenu,
    videoProduct,
    newProduct,
    ecosystemList,
    partner,
  },
  mounted() {
    document.title = "关于我们-首页";
  },
  methods: {
    handleLoginSuccess(toPage) {
      let redirectPath = this.redirectPath || toPage;
      this.resetSetItem("needLogin", JSON.stringify({ value: false }));
      this.showLoginForm = false;
      console.log("handleLoginSuccess-->toPage=" + redirectPath);
      this.redirectPath = "/";
      this.$router.push({ path: redirectPath });
    },
    handleLoginClose() {
      this.showLoginForm = false;
      this.activeTab = "home";
      if (this.redirectPath != "/") {
        this.redirectPath = "/";
        this.$router.push({ path: this.redirectPath });
      }
    },

    // 获取注册用户数
    async getUrlBrowseRegUserStatTheme1() {
      try {
        const { data } = await urlBrowseUserStatTheme1({});
        console.log("xxxxsss", data);
      } catch (error) {
        console.log("urlBrowseUserStatTheme1", error);
      }
    },
    async getIndustryInformation() {
      try {
        const { data } = await getIndustryInformationPage({
          // pageSize: 100,
          // pageNum: 1,
          current: 1,
          size: 20,
          industryInformationClassId: -1,
        });
        let records = data.records || [];
        records.forEach((element) => {
          let updateTime = element.updateTime || "";
          element.dateText = updateTime;
          if (updateTime) {
            element.dateText = updateTime.split(" ")[0];
          }
        });
        this.newsList = records;
        console.log("dasdadasd", records);
      } catch (error) {
        console.log("获取资讯报错", error);
      }
    },
    toNewDetail(item) {
      var url = "";
      if (item.industryDetailsType == 1) {
        url = item.informationDetailsUrl;
      } else {
        let baseUrl = "https://api.yanxin7.cn/api";
        if (
          window.location.origin.includes("localhost") ||
          window.location.origin.includes("dev.yanxin7.cn")
        ) {
          // 在开发环境，用开发链接
          baseUrl = "https://dev.yanxin7.cn/api";
        }
        url = baseUrl + "/v1/industry/detail?industryId=" + item.informationId;
      }
      window.open(url);
    },
    handleTabClick(tab) {
      let token = this.$store.state.account.token;
      console.log("tap------", tab);
      if (tab.name == "quation") {
        this.toPath("/recruit/index");
      }
      if (tab.name == "certificate") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/engineering/index");
      }

      if (tab.name == "personnel") {
        if (!token) {
          this.showLoginForm = true;
          return;
        }
        this.toPath("/home/index");
      }
      if (tab.name == "record") {
        if (window.location.origin.includes("localhost")) {
          window.open("https://dev.yanxin7.cn" + "/bh_web/#/")
          return
        }
        window.open(window.location.origin + "/bh_web/#/")
        return
      }

      let theUrlPrefix = "https://api.yanxin7.cn";
      if (window.location.origin.includes("trial.yanxin7.cn")) {
        theUrlPrefix = "https://trial.yanxin7.cn";
      } else if (
        window.location.origin.includes("dev.yanxin7.cn") ||
        window.location.origin.includes("localhost")
      ) {
        theUrlPrefix = "https://dev.yanxin7.cn";
      }

      if (tab.name == "project") {
        console.log("点击数字机械");
        const w = window.open("about:blank");
        // w.location.href = "https://www.bangjiantong.com/pc/mechanics.html";
        w.location.href =
          theUrlPrefix + `/erp/#/machine_login?token=${token}&loginType=7`;
      }
      if (tab.name == "creditscore") {
        console.log("点击智慧工地");
        // let url=`${window.location.origin}/erp/#/site_login?token=${token}&loginType=6`
        const w = window.open("about:blank");
        // w.location.href = theUrlPrefix + "/erp/#/smartSite/home";
        w.location.href =
          theUrlPrefix + `/erp/#/site_login?token=${token}&loginType=6`;
      }
    },

    toEnterpriseService(routerPath = "/insurance/home/index") {
      const host = window.location.host;
      let targetUrlPrefix = "";
      // TODO: 测试环境条测试，正式跳正式
      if (
        host.includes("dev.yanxin7.cn") ||
        host.includes("localhost") ||
        host.includes("192.168")
      ) {
        targetUrlPrefix = "https://dev.yanxin7.cn" + "/bxbh/#";
      } else {
        targetUrlPrefix = window.location.origin + "/bxbh/#";
      }
      window.open(targetUrlPrefix + routerPath);
    },

    toPath(url) {
      console.log("url", url);

      let vRouter = this.$router.resolve({
        path: url,
        params: {},
      });
      console.log("vRouter", vRouter);
      window.open(vRouter.href, "_blank");
    },

    // 获取建筑行业服务列表
    async geturlCommonColumnList() {
      try {
        const { data } = await urlCommonColumnList({
          appid: "pcd87133b3134a7dff",
        });
        this.$nextTick(() => {
          this.serviceList = data;
        });
      } catch (error) {
        console.log("ee", error);
      }
    },

    async getCatory() {
      try {
        const { data } = await getCatory({ appid: "pcd87133b3134a7dff" });
        this.companyList = data.filter((item) => item.type == "home");
        console.log("data=xx==>", data);
      } catch (error) {
        console.log("ee", error);
      }
    },

    async geturlSystemList() {
      try {
        const { data } = await urlSystemList({ appid: "pcd87133b3134a7dff" });
        this.videoUrlList = data?.records;
      } catch (error) {
        console.log("videoUrl", error);
      }
    },

    handItem(item) {
      window.open(item.params);
    },
    handNavItem(item, index) {
      this.navActive = index;
    },
  },
};
</script>

<style scoped lang="less">
.flex-between {
  display: flex;
  justify-content: space-between;
}

.main-box {
  padding-top: 30px;
  box-sizing: border-box;
  width: 1520px;
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
